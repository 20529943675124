import React,{Component} from "react";
import moment from 'moment';
import ReactPaginate from "react-paginate";
import {giftLists} from "../../services/giftServices/giftService";

class GiftTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            giftLists: [],
            giftResults: {},
            startIndex: 0,
        }
    }

    async componentDidMount() {
        await this.getGiftLists();
    }

    getGiftLists = async (page = 1) => {
        const getGifts = await giftLists(page);
        if(getGifts.status === "success"){
            this.setState({
                giftLists: getGifts.data.results,
                giftResults: getGifts.data,
                startIndex: (page - 1) * getGifts.data.limit,
            });
        }
    }

    handlePageClick = async (e) => {
        let page = e.selected + 1;
        await this.getGiftLists(page);
    }

    render() {
        return (
            <>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="header pb-0">
                            <h2>
                                Gift List Table{" "}
                            </h2>
                        </div>
                        <div className='card-body'>
                            <div className="body table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Sender Name</th>
                                        <th>Receiver Name</th>
                                        <th>Gift Type</th>
                                        <th>Gifted Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.giftLists.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>{this.state.startIndex + index + 1}</td>
                                                <td>{ item.giftSenderUserId.first_name + ' ' + item.giftSenderUserId.last_name }</td>
                                                <td>{ item.giftReceiverUserId.first_name + ' ' + item.giftReceiverUserId.last_name }</td>
                                                <td>{
                                                    item.giftType === "Subscription" ?
                                                        <>
                                                        {item.giftType} ({item?.giftSubscriptionId?.totalMonths} {item?.giftSubscriptionId?.totalMonths > 1 ? "Months" : "Month"})
                                                        </>
                                                        :
                                                        <>item.giftType</>
                                                }</td>
                                                <td>
                                                    {
                                                        moment(item.createdAt).format('MM-DD-Y')
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                                <div className="float-right">
                                    {
                                        this.state.giftResults.page !== undefined && (
                                            <ReactPaginate breakLabel="..."
                                                           nextLabel="Next"
                                                           containerClassName="pagination"
                                                           className="pagination"
                                                           pageClassName="page-item"
                                                           pageLinkClassName="page-link"
                                                           activeClassName="active"
                                                           previousClassName="page-item"
                                                           nextClassName="page-item"
                                                           previousLinkClassName="page-link"
                                                           nextLinkClassName="page-link"
                                                           breakClassName="page-item"
                                                           breakLinkClassName="page-link"
                                                           onPageChange={(e) => {
                                                               this.handlePageClick(e)
                                                           }}
                                                           pageRangeDisplayed={5}
                                                           forcePage={this.state.giftResults?.page - 1}
                                                           pageCount={this.state.giftResults?.totalPages}
                                                           previousLabel="Previous"
                                                           renderOnZeroPageCount={null}/>
                                        )
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
    
}

export default GiftTable;