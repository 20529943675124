import React,{Component} from "react";
import {Link} from "react-router-dom";

class SubscriptionTable extends Component{
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {
        return(
            <>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="header pb-0">
                            <h2>
                                Subscription Table{" "}
                            </h2>
                            <div className='row'>
                                <div className="col-md-10"></div>
                                <div className="col-md-2">
                                    <Link to="/create-subscription">
                                        <button className="btn btn-info float-right">Create Plan</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="body table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>Plan Id</th>
                                        <th>Plan Name</th>
                                        <th>Plan Price</th>
                                        <th>Plan Duration</th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default SubscriptionTable;