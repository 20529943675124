import React,{Component} from "react";
import {orderList} from "../../services/orderServices/orderService";
import moment from 'moment';
import ReactPaginate from "react-paginate";

class OrderTable extends Component{

    constructor(props) {
        super(props);

        this.state = {
            orderLists: [],
            orderResults: {}
        }
    }

    async componentDidMount() {
        await this.getOrders(1,{});
    }

    getOrders = async (page, filter) => {
        const get = await orderList(page, filter);
        console.log(get)
        if(get.status === "success"){
            this.setState({
                orderLists: get.data,
                orderResults: get
            });
        }else{
            this.setState({
                orderLists: [],
                orderResults: {}
            });
        }
    }

    handlePageClick = async (e) => {
        let page = e.selected + 1;
        await this.getOrders(page);
    }

    filterOrderType = async (e) => {
        await this.getOrders(this.state.orderResults?.page,{
            orderType: e.target.value
        });
    }

    render() {
        return (
            <>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="header pb-0">
                            <h2>
                                Order List Table{" "}
                                <small>
                                    Here is all orders from app
                                </small>
                            </h2>
                            <div className='row pt-5'>
                                <div className={'col-md-8'}></div>
                                <div className='col-md-2'>
                                    <select className='form-control' name={'filter-user'} onChange={(e) => {  this.filterOrderType(e)} }>
                                        <option value={''}>Select Order Type</option>
                                        <option value={'Individual'}>Individual</option>
                                        <option value={'As Gift'}>As Gift</option>
                                    </select>
                                </div>
                                <div className='col-md-2'>
                                    <select className='form-control' name={'filter-user'}>
                                        <option value={''}>Select Vendor</option>
                                        <option value={'individual_user'}>Test Vendor 1</option>
                                        <option value={'corporate_user'}>Test Vendor 2</option>
                                        <option value={'vendor_user'}>Test Vendor 3</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="body table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>Order Id</th>
                                        <th>Vendor Name</th>
                                        <th>Customer Name</th>
                                        {/*<th>Product</th>*/}
                                        {/*<th>Product Category</th>*/}
                                        <th>Order Date</th>
                                        <th>Order Status</th>
                                        <th>Order Type</th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.orderLists.map((order, index) =>
                                            <tr key={order.order.id}>
                                                <td>#00{index + 1}</td>
                                                <td>Test Vendor</td>
                                                <td>{ order?.order.userId?.name }</td>
                                                {/*<td>Hair Oil</td>*/}
                                                {/*<td>Cosmetic</td>*/}
                                                <td>
                                                    {
                                                        moment(order.order.createdAt).format('MM-DD-Y')
                                                    }
                                                </td>
                                                <td>{
                                                    order?.order?.shippingStatus
                                                }</td>
                                                <td>{
                                                    order?.order?.orderType
                                                }</td>
                                                <td className="text-center">
                                                    <button className="btn btn-info">
                                                        <i className={'icon-eye'}></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                                <div className="float-right">
                                    {
                                        this.state.orderResults.page !== undefined && (
                                            <ReactPaginate breakLabel="..."
                                                           nextLabel="Next"
                                                           containerClassName="pagination"
                                                           className="pagination"
                                                           pageClassName="page-item"
                                                           pageLinkClassName="page-link"
                                                           activeClassName="active"
                                                           previousClassName="page-item"
                                                           nextClassName="page-item"
                                                           previousLinkClassName="page-link"
                                                           nextLinkClassName="page-link"
                                                           breakClassName="page-item"
                                                           breakLinkClassName="page-link"
                                                           onPageChange={(e) => {
                                                               this.handlePageClick(e)
                                                           }}
                                                           pageRangeDisplayed={5}
                                                           forcePage={this.state.orderResults?.page - 1}
                                                           pageCount={this.state.orderResults?.totalPages}
                                                           previousLabel="Previous"
                                                           renderOnZeroPageCount={null}/>
                                        )
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default OrderTable;