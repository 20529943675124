import React from "react";
import { connect } from "react-redux";
import { Route, Switch ,withRouter} from "react-router-dom";
import Login from "./screens/Login";
import dashboard from "./screens/Dashbord/Dashbord";
import NavbarMenu from "./components/NavbarMenu";
import forgotpassword from "./screens/Auth/ForgotPassword";
import AuthRoute from './util/AuthRoute';

// needed components
import users from './screens/User/User'
import interest from './screens/Interest/Interset'
import EditUser from "./screens/User/editUser";
import AdminUser from "./screens/AdminUser/adminUser";
import AddAdminUser from "./screens/AdminUser/addAdminUser";
import EditAdminUser from "./screens/AdminUser/editAdminUser";
import OrderList from "./screens/Order/OrderList";
import Subscription from "./screens/Subscription/Subscription";
import CreateSubscription from "./screens/Subscription/CreateSubscription";
import UserInInterest from "./screens/Interest/UserInInterest";
import Payment from "./screens/Payment/Payment";
import Gift from "./screens/Gift/Gift";
import Rating from "./screens/Rating/Rating";

window.__DEV__ = true;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
    };
  }
  render() {

    let res = window.location.pathname;

    // let baseUrl = process.env.PUBLIC_URL;
    // baseUrl = baseUrl.split("/");
    // res = res.split("/");
    // res = res.length > 0 ? res[baseUrl.length] : "/";
    // res = res ? res : "/";
    const activeKey1 = res;
    return (
      <div id="wrapper">
        {
        activeKey1 === "/login" ||
        activeKey1 === "/registration" ||
        activeKey1 === "/lockscreen" ||
        activeKey1 === "/forgotpassword" ||
        activeKey1 === "/page404" ||
        activeKey1 === "/page403" ||
        activeKey1 === "/page500" ||
        activeKey1 === "/page503" ||
        activeKey1 === "/maintanance" ? (
            <Switch>
              {/* <Route exact path={`${process.env.PUBLIC_URL}`} component={Login} /> */}
              <Route
                exact
                path="/login"
                component={Login}
              />
              <Route
                exact
                path="/forgotpassword"
                component={forgotpassword}
              />
            </Switch>
        ) : (
          <>
              <NavbarMenu history={this.props.history} activeKey={activeKey1} />
              <div id="main-content">
                <Switch>
                  <Route>
                    <AuthRoute>
                      <Route
                          exact
                          path="/"
                          component={dashboard}
                      />
                      <Route
                          exact
                          path="/dashboard"
                          component={dashboard}
                      />
                      <Route
                          exact
                          path="/registered-users"
                          component={users}
                      />
                      <Route
                          exact
                          path="/edit-user/:userId"
                          component={EditUser}
                      ></Route>
                      <Route
                          exact
                          path="/interest"
                          component={interest}
                      />
                      <Route
                          exact
                          path="/user-in-interest/:interestId"
                          component={UserInInterest}
                      ></Route>
                      <Route
                          exact
                          path="/admin-users"
                          component={AdminUser}
                      ></Route>
                      <Route
                        exact
                        path="/add-admin-user"
                        component={AddAdminUser}
                      ></Route>
                      <Route
                          exact
                          path="/edit-admin-user/:userId"
                          component={EditAdminUser}
                      ></Route>
                      <Route
                          exact
                          path="/subscription"
                          component={Subscription}
                      ></Route>
                      <Route
                          exact
                          path="/create-subscription"
                          component={CreateSubscription}
                      ></Route>
                      <Route
                          exact
                          path="/order-list"
                          component={OrderList}
                      ></Route>
                      <Route
                          exact
                          path="/payment-list"
                          component={Payment}
                      ></Route>
                      <Route
                          exact
                          path="/gift-list"
                          component={Gift}
                      ></Route>
                      <Route
                          exact
                          path="/rating-list"
                          component={Rating}
                      ></Route>
                      </AuthRoute>
                  </Route>
                </Switch>
              </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ loginReducer }) => ({
  isLoggedin: loginReducer.isLoggedin,
});

export default withRouter(connect(mapStateToProps, {})(App));
