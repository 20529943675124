import React,{Component} from "react";
import moment from 'moment';
import ReactPaginate from "react-paginate";
import {changePaymentStatus, paymentLists} from "../../services/paymentServices/paymentService";
import swal from "sweetalert2";

class PaymentTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            paymentLists: [],
            paymentResults: {},
            startIndex: 0,
            vendorId: 0,
            dateRange: "",
        }
    }

    async componentDidMount() {
        await this.getPaymentList();
    }

    getPaymentList = async (page = 1) => {
        const getPayment = await paymentLists(page);
        if(getPayment.status === "success"){
            this.setState({
                paymentLists: getPayment.data.results,
                paymentResults: getPayment.data,
                startIndex: (page - 1) * getPayment.data.limit,
            });
        }
    }

    handlePageClick = (e) => {
        let page = e.selected + 1;
        this.getPaymentList(page);
    }

    changePaymentStatus = async (e,userId,paymentId) => {
        const value = e.target.value;
        const data = {
            userId,
            paymentId,
            status: value,
        }

        const changePayment = await changePaymentStatus(data);
        if(changePayment.status === "success"){
            swal.fire({
                icon: "success",
                title: "Success!",
                text: changePayment.message
            });
        }else{
            swal.fire({
                icon: "error",
                title: "Error!",
                text: changePayment.message
            });
        }
    }
    render() {
        return (
            <>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="header pb-0">
                            <h2>
                                Payment List Table{" "}
                            </h2>
                            <div className='row pt-5'>
                                <div className={'col-md-8'}></div>
                                <div className='col-md-2'>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className='col-md-2'>
                                    <select className='form-control' name={'filter-user'}>
                                        <option value={''}>Select Vendor</option>
                                        <option value={'individual_user'}>Test Vendor 1</option>
                                        <option value={'corporate_user'}>Test Vendor 2</option>
                                        <option value={'vendor_user'}>Test Vendor 3</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="body table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>Payment Id</th>
                                        <th>Vendor Name</th>
                                        <th>Product Name</th>
                                        <th>Order Date</th>
                                        <th>Customer Info</th>
                                        <th>Price</th>
                                        <th>Payment Status</th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.paymentLists.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>{this.state.startIndex + index + 1}</td>
                                                <td>Test Vendor</td>
                                                <td>Test Product</td>
                                                <td>
                                                    {
                                                        moment(item.createdAt).format('MM-DD-Y')
                                                    }
                                                </td>
                                                <td>{
                                                    item?.userId?.first_name + ' ' + item?.userId?.last_name
                                                }</td>
                                                <td>{
                                                    item?.price
                                                }</td>
                                                <td className="text-capitalize text-center">
                                                    <select style={{width: "60%"}} className="form-control"
                                                            defaultValue={item?.paymentStatus}
                                                            onChange={(e) => {
                                                                this.changePaymentStatus(e,item?.userId?.id,item.id)
                                                            }} >
                                                        <option value="paid">Paid</option>
                                                        <option value="unpaid">UnPaid</option>
                                                    </select>
                                                </td>
                                                <td className="text-center">
                                                    <button className="btn btn-info">
                                                        <i className={'icon-pencil'}></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                                <div className="float-right">
                                    {
                                        this.state.paymentResults.page !== undefined && (
                                            <ReactPaginate breakLabel="..."
                                                           nextLabel="Next"
                                                           containerClassName="pagination"
                                                           className="pagination"
                                                           pageClassName="page-item"
                                                           pageLinkClassName="page-link"
                                                           activeClassName="active"
                                                           previousClassName="page-item"
                                                           nextClassName="page-item"
                                                           previousLinkClassName="page-link"
                                                           nextLinkClassName="page-link"
                                                           breakClassName="page-item"
                                                           breakLinkClassName="page-link"
                                                           onPageChange={(e) => {
                                                               this.handlePageClick(e)
                                                           }}
                                                           pageRangeDisplayed={5}
                                                           forcePage={this.state.paymentResults?.page - 1}
                                                           pageCount={this.state.paymentResults?.totalPages}
                                                           previousLabel="Previous"
                                                           renderOnZeroPageCount={null}/>
                                        )
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PaymentTable;