import axiosConfig from "../../config/axiosConfig";

const url = 'users';

export const userList = async (page = 1, limit = 10) => {
    const getUserList = await axiosConfig.get(url,{
        params:{
            page,limit,
            role: 'user',
            status: 'active'
        }
    }).then(res => {
        if(res.status === 200){
            return res.data;
        }
        return { status: "error", message: "User List not found." }
    }).catch((err) => {
        return { status: 'error', message: err.message };
    });

    return getUserList;
}

export const userDetails = async (userId) => {
    const getDetails = await axiosConfig.get(url + '/' + userId).then(res => {
        if(res.status === 200){
            return { status: 'success', data: res.data };
        }
        return { status: "error", message: "User not found." }
    }).catch((err) => {
        return { status: 'error', message: err.message };
    });

    return getDetails;
}

export const updateUser = async (data,userId) => {
    const update = await axiosConfig.patch(url + '/update-user/' + userId,data).then(res => {
        if(res.status === 200){
            return { status: 'success', data: res.data };
        }
        return { status: "error", message: "User not updated." }
    }).catch((err) => {
        return { status: 'error', message: err.message };
    });

    return update;
}

export const deleteUser = async (userId) => {
    const remove = await axiosConfig.delete(url + '/' + userId).then(res => {
        if(res.status === 200){
            return { status: 'success', data: res.data };
        }
        return { status: "error", message: "Something went wrong while removing user." }
    }).catch((err) => {
        return { status: 'error', message: err.message };
    });

    return remove;
}